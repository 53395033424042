<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="monthly_invoices"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.menu.account-book.monthly-invoices"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-right"
                                @click="nextMonth()"
                            />
                        </b-form>
                    </template>
                    <template #cell(customer_id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id}, refreshTable)">
                            {{ row.item.customer_id }}
                        </a>
                    </template>
                    <template #cell(customer)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id}, refreshTable)">
                            {{ row.item.customer }}
                        </a>
                    </template>
                    <template #cell(customer_nr)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id}, refreshTable)">
                            {{ row.item.customer_nr }}
                        </a>
                    </template>
                    <template #cell(generated)="row">
                        <CBadge :color="row.item.generated ? 'success' : 'danger' ">
                            {{ row.item.generated ? $t('common.button.yes') : $t('common.button.no') }}
                        </CBadge>
                    </template>
                    <template #cell(netto)="row">
                        {{ row.item.netto | priceFormat }} &euro;
                    </template>
                    <template #cell(brutto)="row">
                        {{ row.item.brutto | priceFormat }} &euro;
                    </template>
                    <template #cell(invoice_file)="row">
                        <a :href="$root.getDocumentUrl(row.item.invoice_file)"
                           v-if="row.item.invoice_file"
                           @click.prevent="$root.openDocument(row.item.invoice_file)"
                        >{{ row.item.invoice_file }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                v-if="!row.item.generated"
                                @click="generate(row.item.id,false)"
                            >
                                {{ $t('common.button.generate') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="!row.item.generated"
                                @click="generate(row.item.id,true)"
                            >
                                {{ $t('common.button.preview') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'MonthlyInvoices',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            saveFilters: true,
            dateField: 'date'
        }
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/monthly-invoices'
            }
        },
        generate(id, preview = false) {
            return window.axios.post(window.apiUrl
                + '/monthly-invoices/generate'
                , {id, preview: preview ? true : false}).then(response => {

                if (response.data.filename) {
                    this.$root.openDocument(response.data.filename)
                }
            })
        },
        getActions() {
            return []
        },
    },
}
</script>